import React, {useEffect, useState} from "react";
import {useAccount, useSwitchChain, useBalance} from "wagmi";
import {zkSync} from "wagmi/chains";
import {useEthersProvider} from "../providers/ethers";
import {useZkSyncProvider} from "../providers/zksync";
import {Contract} from "zksync-ethers";
import BigNumber from "bignumber.js";
import axios from "axios";

import * as ethers from "ethers";
import {store} from "../state";
import {useWalletDispatch} from "../state/hooks";
import {walletSlice} from "../state/reducer";

import ZorroGooners from './mint.json'
import Whitelist from './whitelisted.json'
import { StandardMerkleTree } from "@openzeppelin/merkle-tree";

const ZorroGoonersContract = '0x388ac4fed9be6fa441071daac3cdb3967d88ac65'

BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_DOWN});

export const walletHookContext = React.createContext<any>(undefined);

export const useWalletHookFn = () => {
  const dispatch = useWalletDispatch();

  const {address, isConnected, chain} = useAccount();

  const account = address;
  const {switchChain} = useSwitchChain();

  const provider = useEthersProvider({chainId: 324});
  const zk_signer = useZkSyncProvider({chainId: 324});

  const wagmiBal = useBalance({address: provider ? account : undefined, query: {enabled: true, refetchInterval: 1_000 * 5}});

  const isActive = isConnected;
  const chainId = chain?.id;

  const [eth_bal, setETHBal] = useState<any>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {

  }, []);

  useEffect(() => {
    // should be called once on dapp load once accounts and signers are configured 
    if (wagmiBal.data && provider && account && zk_signer) {
      setETHBal(wagmiBal.data?.value);
      fetch()
    }
    return () => {};
  }, [wagmiBal, provider, account, zk_signer]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (provider && account) {
      checkNetwork();
    }

    return () => {};

  }, [isActive, provider, account, chainId]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = async() => {
    await getNftBalance()
    var _canMint = await canMint()
    dispatch(walletSlice.actions.updateCanMint(_canMint));
    dispatch(walletSlice.actions.updateIsWhitelist(getIsWhitelisted()));
    var price = await axios.create().get('https://api.coingecko.com/api/v3/simple/price?ids=zorro&vs_currencies=eth')
    dispatch(walletSlice.actions.updateZorroPrice(new BigNumber(price.data.zorro.eth)));
    console.log(price.data.zorro.eth)
    console.log(_canMint)
  }

  useEffect(() => {
    if (provider && account && eth_bal != null && initialized === false) {
      setInitialized(true)

      fetch()

    }
    return () => {};


  }, [eth_bal]) // eslint-disable-line react-hooks/exhaustive-deps


  const checkNetwork = async () => {
    dispatch(walletSlice.actions.updateWrongChain(false));
    if (chain && chain.id === zkSync.id) {
    } else {
      dispatch(walletSlice.actions.updateWrongChain(true));
      switchChain({chainId: zkSync.id});
    }
  };

  const chainSafetyCheck = async () => {
    await checkNetwork();
    if (store.getState().wrongChain === true) throw Error("Wrong Chain");
  };

  const getNftBalance = async () => {
    const nftManager = new Contract(ZorroGoonersContract, ZorroGooners, provider)

    var bal = await nftManager.balanceOf(account)
    var isPublic = await nftManager.isPublic()
    var totSup = await nftManager.totalSupply()

    dispatch(walletSlice.actions.updateNftBalance(Number(bal)));
    dispatch(walletSlice.actions.updatePublicSale(isPublic));
    dispatch(walletSlice.actions.updateTotalSupply(Number(totSup)));

    console.log(Number(bal))
    console.log(isPublic)
    return bal
  }

  const canMint = async () => {
    const nftManager = new Contract(ZorroGoonersContract, ZorroGooners, await getZkSigner())
    var isPublic = await nftManager.isPublic()

    try{
        if(isPublic){
            await nftManager.mint.staticCall([], {value: new BigNumber(0.04).times(Math.pow(10,18)).toFixed()})
            return true
        } else {
            var values = [
            ];
            
            for(let i in Whitelist)
                values.push([Whitelist[i]])
            
            //values = [['0xd6AD2D748dA4F8eCb9e971A8F07AEeb93A31DDa8']]
            const tree = StandardMerkleTree.of(values, ["address"]);
            for (const [i, v] of tree.entries()) {
                if (v[0].toLowerCase() === account?.toLowerCase()) {
                  const proof = tree.getProof(i);
                  console.log('Value:', v);
                  console.log('Proof:', proof);
                  await nftManager.mint.staticCall(proof, {value: new BigNumber(0.03).times(Math.pow(10,18)).toFixed()})
                  return true
                }
            }
        }
    } catch(e){
        return false
    }

    return false
  }

  const getZkSigner = async () => {
    return await (zk_signer as ethers.BrowserProvider)?.getSigner();
  };


  const mintNft = async () => {
    await chainSafetyCheck()
    const nftManager = new Contract(ZorroGoonersContract, ZorroGooners, await getZkSigner())
    try{
        var tx = await nftManager.mint([], {value: new BigNumber(0.04).times(Math.pow(10,18)).toFixed()})
        await tx.wait()
        await fetch()
        return true
    } catch(e){
        console.log(e)
        return false
    }
  }

  const mintNftWhitelist = async () => {
    await chainSafetyCheck()

    const nftManager = new Contract(ZorroGoonersContract, ZorroGooners, await getZkSigner())
    try{
        var values = [
        ];
        
        for(let i in Whitelist)
            values.push([Whitelist[i]])
        

        //values = [['0xd6AD2D748dA4F8eCb9e971A8F07AEeb93A31DDa8']]
        // (2)
        const tree = StandardMerkleTree.of(values, ["address"]);
        for (const [i, v] of tree.entries()) {
            if (v[0].toLowerCase() === account?.toLowerCase()) {
              const proof = tree.getProof(i);
              console.log('Value:', v);
              console.log('Proof:', proof);
              var tx = await nftManager.mint(proof, {value: new BigNumber(0.03).times(Math.pow(10,18)).toFixed()})
              await tx.wait()
              await fetch()
              return true
            }
        }

    } catch(e){
        console.log(e)
        return false
    }
  }

  const getIsWhitelisted = () => {

    for(let i in Whitelist){
        if(Whitelist[i].toLowerCase() === account?.toLowerCase())
            return true
    }
    
    return false
  }
  

  return {
    getIsWhitelisted,
    mintNftWhitelist,
    mintNft
  };
};


export const WalletHook = ({children}: {children: any}) => {
  const value = useWalletHookFn();

  return <walletHookContext.Provider value={value}>{children}</walletHookContext.Provider>;
};

export function useWalletHook<useTyping = false>() {
  const context = React.useContext(walletHookContext);
  if (!context) throw Error("useWalletHook can only be used within the Web3ReactProvider component");
  return context as useTyping extends true ? ReturnType<typeof useWalletHookFn> : any;
}
