import { Row } from "antd";
import { withTranslation } from "react-i18next";
import {  PngIcon } from "../../common/SvgIcon";
import ZorroLogo from '../../assets/logo_white.png'
import Container from "../../common/Container";
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

import {
  NavLink,
  Extra,
  LogoContainer,

  FooterContainer,

} from "./styles";



const Footer = () => {

  return (
    <>
      <Extra>
        <Container border={false}>
          <Row
            justify="space-between"
            align="middle"
            style={{ padding: "5rem", paddingBottom: "5rem", maxWidth: '1400px', margin: 'auto', marginTop: '5rem' }}

          >
            <NavLink to="/">
              <LogoContainer>
                <PngIcon
                  src={ZorroLogo}
                  aria-label="homepage"
                  width="125px"
                  height="66px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>

                <TwitterIcon style={{color: 'white', "marginRight": '10px', cursor: "pointer"}} onClick={() => window.open("https://x.com/zkzorro")}/>
                <TelegramIcon style={{color: 'white', cursor: "pointer"}} onClick={() => window.open("https://t.me/zorrogoons")}/>

            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);