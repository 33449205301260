import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query/react";
import {walletSlice} from "./reducer";

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const store = configureStore({
  reducer: walletSlice.reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: true, serializableCheck: false})
});

//store.dispatch(updateVersion())

setupListeners(store.dispatch);

export type WalletState = ReturnType<typeof store.getState>;
export type WalletDispatch = typeof store.dispatch;
