import { useState } from "react";
import { NavLink } from "react-router-dom";

import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { PngIcon } from "../../common/SvgIcon";
import HeaderLogo from '../../assets/logo_white.png'
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
//import { Button } from "../../common/Button";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  NotHiddenSocials,
  Menu,
  //CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>

  <CustomNavLink to="/">
            <Span>{t("HOME")}</Span>
          </CustomNavLink>

      </>
    );
  };

  /*

          <CustomNavLink to="/mint">
            <Span>{t("MINT")}</Span>
          </CustomNavLink>
                <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("IT'S A ME, ZORRO")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("info")}>
          <Span>{t("VERY WALLETS")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("tokenomics")}>
          <Span>{t("TOKENOMICS")}</Span>
        </CustomNavLinkSmall>

        <Button fixedWidth={true} onClick={() => window.open("https://game.zorro.gg", "_blank")}>Play Game</Button>

        */

  return (
    <HeaderSection>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <PngIcon src={HeaderLogo} width="120px" height="66px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <NotHiddenSocials>
            <div style={{display: 'flex', zIndex: '100', width: '300px',  justifyContent: "center", alignItems: 'center'}}>
                <TwitterIcon style={{color: 'white', "marginRight": '10px', cursor: "pointer"}} onClick={() => window.open("https://x.com/zkzorro")}/>
                <TelegramIcon style={{color: 'white', cursor: "pointer", marginRight: '20px'}} onClick={() => window.open("https://t.me/zorrogoons")}/>
            </div>


          </NotHiddenSocials>

          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem", marginTop: "0.6rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
    </HeaderSection>
  );
};

const CustomNavLink = styled(NavLink)`

  display: inline-block;
  text-align: center;

  text-decoration: none;
  font-size: 0.9rem;
  color: #fff;
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  :hover {
    text-decoration: none;

  }

  @media only screen and (max-width: 768px) {
    color: #0f0f10;
    margin: 1.25rem 2rem;
  }



`


export default withTranslation()(Header);
