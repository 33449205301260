import React, {ReactNode} from "react";
import {WalletHook} from "../web3/walletHook";
import {Provider} from "react-redux";
import {store} from "../state";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import {createWeb3Modal} from "@web3modal/wagmi/react";
import {http, createConfig, WagmiProvider} from "wagmi";
import {zkSync} from "wagmi/chains";
import {walletConnect, injected, coinbaseWallet} from "wagmi/connectors";

//walletconnectv2 id
const projectId = "0b27589599bc305b464d3559c77b9c5a";
// 2. Create wagmiConfig
const metadata = {
  name: "Zorro.gg",
  description: "Complete Degeneracy on zkSync",
  url: "https://zorro.gg",
  icons: [''],
  verifyUrl: "https://zorro.gg",
};

//@ts-ignore
const config = createConfig({
  chains: [zkSync],
  transports: {
    [zkSync.id]: http(),
  },
  connectors: [
    walletConnect({projectId, metadata, showQrModal: false, isNewChainsStale: false}),
    injected({shimDisconnect: true}),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    }),
  ],
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  defaultChain: zkSync,
  chainImages: {
    324: "https://raw.githubusercontent.com/bxpana/zksync-community-brand-assets/main/zkSync/zkSync%20Era%E2%88%8E%20/Icons/icon_zkSync_Era.png",
  },
});

const queryClient = new QueryClient({
  /*
  defaultOptions:{
    queries: {
      refetchInterval: 1_000 * 5, // 5 seconds ,
    }
  }
  */
});

interface ConnectionProviderProps {
  children: ReactNode;
}

export const Providers = ({children}: ConnectionProviderProps) => {  
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(true);

    return () => {};
  }, []);

  return ready ? (
    <WagmiProvider config={config}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <WalletHook>{children}</WalletHook>
        </QueryClientProvider>
      </Provider>
    </WagmiProvider>
  ) : null;
};